import React, { useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { Grid, Container, Button } from '@mui/material';
import { makeStyles } from "@mui/styles";
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import Tooltip from '@mui/material/Tooltip';
import ReactHtmlParser from "html-react-parser";
import {faqList} from "../../faqs/service";

const extractBrochureEndpoint = (content) => {
    const faqFeeContentId = 372
    const feeFaq = content.find(faq => faq.CONTENTID === faqFeeContentId);
    const href = ReactHtmlParser(feeFaq.CONTENTDETAIL)?.find(e => e.type === 'a')?.props?.href;
    return href ?? '#';
}

const onBrochureClicked = async (brochureEndpoint) => {
    const response = await fetch(brochureEndpoint);
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    window.open(url, 'pdf', 'height=600,width=600,scrollbars=yes,status=yes');
}

const useStyles = makeStyles((theme) => ({
    mainbg: {
        backgroundColor: theme.palette.secondary.light,
    },
    main: {
        paddingTop: 10
    },
    containerTop: {
        marginTop: '6%'
    },

    imagespace: {
        margin: 10
    },
    paymobile: {
        width: '100%',
        height: 300,
        objectFit: 'contain'
    }
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const Steps = () => {
    const classes = useStyles()
    const [value, setValue] = React.useState(0);
    const [brochureEndpoint, setBrochureEndpoint] = React.useState('#');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        (async () => {
            const faqs = await faqList();
            setBrochureEndpoint(extractBrochureEndpoint(faqs.data.content));
        })();
    }, []);

    return (
        <>
            <Container maxWidth="lg" className={classes.containerTop}>
                <Typography variant='h5' fontFamily='BentonSansPro_Regular' color='primary.dark'>
                    GET STARTED IN 3 STEPS
                </Typography>
                <Grid container justifyContent='center' className={classes.main} >
                    <Grid item xs={12} className={classes.mainbg}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" indicatorColor="primary" textColor='primary' >
                                    <Tab label="Step One" {...a11yProps(0)} />
                                    <Tab label="Step Two" {...a11yProps(1)} />
                                    <Tab label="Step Three" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={value} index={0} fontFamily='BentonSansPro_Regular'>
                                <img src={require('../../assets/images/icon_triangle.png')} style={{ marginRight: 15 }} alt="" />
                                A PayCard profile is the secure and simple alternative to cash and cheque payments.<br />
                                <Tooltip title="Sign me up!">
                                    <Button>
                                        <PersonAddAltRoundedIcon onClick={() => onBrochureClicked(brochureEndpoint)} fontSize="large" color='primary'/> <br/><br/>
                                    </Button>
                                </Tooltip>
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                Order the cards<br />
                                <img src={require('../../assets/images/icon_triangle.png')} alt="" style={{ marginRight: 15 }} /> Once your profile has been created and verified, you'll need to log in and order cards.<br />
                                <img src={require('../../assets/images/icon_triangle.png')} alt="" style={{ marginRight: 15 }} /> Each person that you pay with a PayCard will need a physical card.<br />
                                <img src={require('../../assets/images/icon_triangle.png')} alt="" style={{ marginRight: 15 }} /> That's what they'll use at stores to pay for goods and at ATMs to draw cash.<br /><br />
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                Top-up with funds<br />
                                <img src={require('../../assets/images/icon_triangle.png')} alt="" style={{ marginRight: 15 }} /> Top-up your profile with funds, and then load the funds to any PayCard.<br />
                                <img src={require('../../assets/images/icon_triangle.png')} alt="" style={{ marginRight: 15 }} /> You can make payments at any time.<br />
                                <img src={require('../../assets/images/icon_triangle.png')} alt="" style={{ marginRight: 15 }} /> Schedule payments to remove the hassle of remembering to make them yourself.<br /><br />
                            </TabPanel>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' className={classes.containerTop} >
                    <Grid item xs={12} className={classes.mainbg}>
                        <Box sx={{ width: '100%', padding: 10 }}>
                            <Grid container flexDirection='row'>
                                <Grid item xs={8} >
                                    <Typography variant='h3' color='#858d9d' fontFamily='BentonSansPro_Regular'>
                                        The PayCard Mobile App
                                    </Typography>
                                    <Typography variant="title" color='#858d9d' fontFamily='BentonSansPro_Regular'>
                                        <br /><br />Notifications on deposits and transactions<br />
                                        Manage your card from your phone<br />
                                        Helps you save for what matters<br />
                                        Easy-to-set savings goals<br />
                                        Smart budgeting<br />
                                    </Typography>
                                    <br /><br /><a href="https://play.google.com/store/apps/details?id=com.engageft.standard.thepc&hl=en_ZA" target="_blank" rel="noreferrer">
                                        <img src={require('../../assets/images/logo_content_googlePlay.png')} alt="" className={classes.imagespace} />
                                    </a>
                                    <a href="https://apps.apple.com/hn/app/the-paycard/id1356683074?l=en" target="_blank" rel="noreferrer">
                                        <img src={require('../../assets/images/logo_content_appStore.png')} alt="" className={classes.imagespace} />
                                    </a>
                                </Grid>
                                <Grid item xs={4}>
                                    <img src={require('../../assets/images/img_content_mobileApp.png')} alt="" className={classes.paymobile}>
                                    </img>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}

export default Steps;
