import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetMfa } from './service';

export const verifyResetMfa = createAsyncThunk(
    'verify/reset_mfa',
    async (params, { rejectWithValue }) => {
        try {
            const response = await resetMfa(params);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
)
