import { makeStyles } from "@mui/styles";
import Container from '@mui/material/Container';
import Title from '../../common/components/base/title/index';
import ResetMfaForm from './ResetMfaForm';

const useStyles = makeStyles((theme) => ({
    mainbg: {
        backgroundColor: theme.palette.background.paper,
        fontSize: '14px'
    }
}));

const ResetMfa = () => {
    const classes = useStyles();
    return (
        <div className={classes.mainbg}>
            <Container maxWidth='md'>
                <Title title='Reset MFA' subtitle='Please input the OTP code to reset your MFA.'/>
                <ResetMfaForm />
            </Container>
        </div>
    );
}

export default ResetMfa;
