import React, { useEffect } from 'react';
import store from './store';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { getAuthState } from '../login/slice';
import rg4js from 'raygun4js';
import { useSelector } from 'react-redux';
import { Box } from '@mui/material';

import OrderCard from '../cards/orderCard/orderCardForm';
import Dashboard from '../dashboard';
import DepositFunds from '../funds/depositFunds';
import Home from '../home';
import Faqs from '../faqs';
import ContactUs from '../contactUs';
import OrderCardConfirm from './../cards/orderCard/orderCardConfirm/index';
import ManageCard from '../cards/manageCard';
import OrderCardInvoice from '../cards/orderCard/orderCardInvoice/index';
import News from '../news';
import PrivacyStatement from '../home/privacyStatement';
import Conditions from '../home/conditions';
import Disclaimer from '../home/disclaimer';
import GetPayCard from '../home/getPayCard';
import GivePayCard from '../home/givePayCard';
import SelectCards from '../cards/allocateCard/cardSelection';
import CardHolderDetails from '../cards/allocateCard/cardHolderDetails';
import CardConfirmation from '../cards/allocateCard/cardConfirmation';
import ResetPassword from '../login/components/ResetPassword';
import LoadFundsIntoCard from '../funds/loadFunds';
import LoadAllocatedCards from '../funds/loadFunds/components/LoadAllocatedCards';
import LoadFundsOntoOption from '../funds/loadFunds/components/LoadFundsOntoOption';
import SearchLoadTo from '../funds/loadFunds/components/SearchLoadTo';
import ConfirmCardLoad from '../funds/loadFunds/components/ConfirmCardLoad';
import BatchLoad from '../funds/batchLoad';
import CancelPendingLoad from '../funds/cancelPendingLoad/index';
import PendingLoadConfirm from '../funds/cancelPendingLoad/pendingLoadConfirm/index';
import TransactionStatement from "../reports/profileHistory/transactionStatement";
import { ProfileHistory } from "../reports/profileHistory";
import EditManageCard from '../cards/manageCard/cardHolderDetails';
import DownloadCardTransactions from '../reports/donwloadCardTransactions/index';
import CardStatement from "../reports/cardStatement/statement";
import CardStatementCardSelection from "../reports/cardStatement/cardSelection";
import CardList from '../reports/cardList';
import DownloadPreviousInvoice from '../reports/donwloadPreviousInvoice/index';
import ScheduledLoads from '../funds/scheduledLoads';
import ConfirmScheduledLoads from '../funds/scheduledLoads/components/ConfirmLoads';
import RegistrationDetails from '../setting/RegistrationDetails';
import UpdateEmail from '../setting/updateEmail';
import UpdatePassword from '../setting/updatePassword';
import ConfirmEmailChange from '../setting/updateEmail/ConfirmEmailChange';
import TermsAndConditions from '../home/termsAndCondition';
import SelectProfile from '../login/components/SelectProfile';
import MfaSetupForm from "../login/components/MfaSetupForm";
import RequiredPasswordReminder from '../login/components/RequiredPasswordReminder';
import ResetMfa from "../setting/resetMfa";

const PASSWORD_REMINDER_PATH = '/required-password-reminder';

const PrivateRoute = ({ children }) => {
    store.dispatch(getAuthState());
    const { isAuthenticated, userPasswordIsTooOld } = store.getState().login;
    const location = useLocation();

    if (isAuthenticated && userPasswordIsTooOld && location.pathname !== PASSWORD_REMINDER_PATH) {
        return <Navigate to={PASSWORD_REMINDER_PATH} state={{ from: location }} />
    }

    return isAuthenticated ? children : <Navigate to='/' state={{ from: location }} />;
}

export const routes = [
    {
        path: '/',
        element: <Home />,
        title: '',
    },
    {
        path: '/faqs',
        element: <Faqs />,
        title: 'FAQs',
    },
    {
        path: '/dashboard/order-card',
        element: <PrivateRoute><OrderCard /></PrivateRoute>,
        title: 'Order Card',
    },
    {
        path: '/dashboard/order-card-confirm',
        element: <PrivateRoute><OrderCardConfirm /></PrivateRoute>,
        title: 'Order Card Confirm',
    },
    {
        path: '/dashboard/order-card-invoice',
        element: <PrivateRoute><OrderCardInvoice /></PrivateRoute>,
        title: 'Order Card Invoice',
    },
    {
        path: '/dashboard/manage-card',
        element: <PrivateRoute><ManageCard /></PrivateRoute>,
        title: 'Manage cards',
    },
    {
        path: '/contactus',
        element: <ContactUs />,
        title: 'Contact Us',
    },
    {
        path: '/dashboard/allocate-card',
        element: <PrivateRoute><SelectCards /></PrivateRoute>,
        exact: true,
        title: 'Allocate Cards'
    },
    {
        path: '/dashboard/allocate-card/card-holder-details',
        element: <PrivateRoute><CardHolderDetails /></PrivateRoute>,
        title: 'Card Holder Details'
    },
    {
        path: '/dashboard/allocate-card/confirm',
        element: <PrivateRoute><CardConfirmation /></PrivateRoute>,
        title: 'Confirm Allocation'
    },
    {
        path: '/conditions',
        element: <Conditions />,
        title: 'Conditions'
    },
    {
        path: '/disclaimer',
        element: <Disclaimer />,
        title: 'Disclaimer'
    },
    {
        path: '/get',
        element: <GetPayCard />,
        title: 'Read more'
    },
    {
        path: '/give',
        element: <GivePayCard />,
        title: 'Read more'
    },
    {
        path: '/news',
        element: <PrivateRoute><News /></PrivateRoute>,
        title: 'News'
    },
    {
        path: '/dashboard',
        element: <PrivateRoute><Dashboard /></PrivateRoute>,
        title: 'Home'
    },
    {
        path: '/reset-password',
        element: <ResetPassword />,
        title: 'Reset Password'
    },
    {
        path: '/dashboard/deposit-funds',
        element: <PrivateRoute><DepositFunds /></PrivateRoute>,
        title: "Deposit Funds",
    },
    {
        path: '/dashboard/load-funds-from',
        element: <PrivateRoute><LoadFundsIntoCard /></PrivateRoute>,
        title: "Load funds from",
    },
    {
        path: '/dashboard/load-funds-from/to',
        element: <PrivateRoute><LoadFundsOntoOption /></PrivateRoute>,
        title: "To",
    },
    {
        path: '/dashboard/load-funds-from/card-loads',
        element: <PrivateRoute><LoadAllocatedCards /></PrivateRoute>,
        title: "Card loads",
    },
    {
        path: '/dashboard/load-funds-from/to/search-card',
        element: <PrivateRoute><SearchLoadTo /></PrivateRoute>,
        title: "Search for a card",
    },
    {
        path: '/dashboard/load-funds-from/card-loads/confirm-load',
        element: <PrivateRoute><ConfirmCardLoad /></PrivateRoute>,
        title: "Confirm load",
    },
    {
        path: '/dashboard/batch-load/*',
        element: <PrivateRoute><BatchLoad /></PrivateRoute>,
        title: "Batch load",
        children: [
            {
                path: 'step1',
                title: 'Step 1',
            },
            {
                path: 'step2',
                title: 'Step 2'
            },
            {
                path: 'step3',
                title: 'Step 3'
            },
            {
                path: 'step4',
                title: 'Step 4'
            },
            {
                path: 'list',
                title: 'List'
            },
        ]
    },
    {
        path: '/dashboard/cancel-pending-load',
        element: <PrivateRoute><CancelPendingLoad /></PrivateRoute>,
        title: "Cancel pending load",
    },
    {
        path: '/dashboard/cancel-pending-load/confirmation',
        element: <PrivateRoute><PendingLoadConfirm /></PrivateRoute>,
        title: "Confirmation",
    },
    {
        path: '/dashboard/profile-history/statement',
        element: <PrivateRoute><TransactionStatement /></PrivateRoute>,
        title: "Transaction statement",
    },
    {
        path: '/dashboard/profile-history',
        element: <PrivateRoute><ProfileHistory /></PrivateRoute>,
        title: "Profile history",
    },
    {
        path: '/privacy-statement',
        element: <PrivacyStatement />,
        title: 'Privacy Statement'
    },
    {
        path: '/deposit-funds',
        element: <PrivateRoute><DepositFunds /></PrivateRoute>,
        title: "Deposit Funds",
    },
    {
        path: '/dashboard/manage-card/card-holder-details',
        element: <PrivateRoute><EditManageCard /></PrivateRoute>,
        title: 'Card Holder Details'
    },
    {
        path: '/dashboard/scheduled-loads',
        element: <PrivateRoute><ScheduledLoads /></PrivateRoute>,
        title: "Scheduled loads"
    },
    {
        path: '/dashboard/scheduled-loads/confirm-loads',
        element: <PrivateRoute><ConfirmScheduledLoads /></PrivateRoute>,
        title: "Confirm loads"
    },
    {
        path: '/dashboard/download-card-transactions',
        element: <PrivateRoute><DownloadCardTransactions /></PrivateRoute>,
        title: 'Download all card transactions'
    },
    {
        path: '/dashboard/card-statement',
        element: <PrivateRoute><CardStatementCardSelection /></PrivateRoute>,
        title: 'Card statements'
    },
    {
        path: '/dashboard/card-statement/statement',
        element: <PrivateRoute><CardStatement /></PrivateRoute>,
        title: 'View a card statement'
    },
    {
        path: '/dashboard/card-list',
        element: <PrivateRoute><CardList /></PrivateRoute>,
        title: 'Card List'
    },
    {
        path: '/dashboard/download-previous-invoice',
        element: <PrivateRoute><DownloadPreviousInvoice /></PrivateRoute>,
        title: 'Download a previous invoice'
    },
    {
        path: '/dashboard/registration-details',
        element: <PrivateRoute><RegistrationDetails /></PrivateRoute>,
        title: 'Update your registration details',
    },
    {
        path: '/dashboard/update-your-password',
        element: <PrivateRoute><UpdatePassword /></PrivateRoute>,
        title: 'Update your password',
    },
    {
        path: '/dashboard/reset-mfa',
        element: <PrivateRoute><ResetMfa /></PrivateRoute>,
        title: 'Reset MFA',
    },
    {
        path: '/dashboard/update-your-email',
        element: <PrivateRoute><UpdateEmail /></PrivateRoute>,
        title: 'Update your email',
    },
    {
        path: '/change-email',
        element: <PrivateRoute><ConfirmEmailChange /></PrivateRoute>,
        title: 'Change email',
    },
    {
        path: '/terms-and-conditions',
        element: <TermsAndConditions />,
        title: 'Terms and Conditions'
    },
    {
        path: '/selectProfile',
        element: <PrivateRoute><SelectProfile /></PrivateRoute>,
        title: "Select Profile",
    },
    {
        path: '/setup-mfa',
        element: <MfaSetupForm />,
        title: 'Setup MFA'
    },
    {
        path: PASSWORD_REMINDER_PATH,
        element: <PrivateRoute><RequiredPasswordReminder /></PrivateRoute>,
        title: "Required Password Reminder",
    },
]

const MyRoutes = () => {
    const userHeader = useSelector(state => state.header.userHeader);
    const location = useLocation();

    useEffect(() => {
        rg4js('trackEvent', { type: 'pageView', path: location.pathname });
    }, [location.pathname])

    useEffect(() => {
        rg4js('setUser', {
            isAnonymous: false,
            fullName: userHeader.FULLNAME
        });
    }, [userHeader.FULLNAME])

    return (
        <Box sx={{minHeight: '45vh'}}>
            <Routes>
                {
                    routes.map(({ path, exact, element }) => (
                        <Route key={path} path={path} exact={exact} element={element} />)
                    )
                }
                <Route path="*" element={<Navigate replace to="/" />} />
            </Routes>
        </Box>
    )
};

export default MyRoutes;
