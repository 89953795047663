import { createSlice } from '@reduxjs/toolkit';
import ReduxStatus from '../../common/constants/ReduxStatus';
import { verifyResetMfa } from "./thunk";


const initialState = {
    data: {},
    status: ReduxStatus.empty,
    errorMessages: []
};

export const resetMfaSlice = createSlice({
    name: 'resetMfa',
    initialState,
    reducers: {},
    extraReducers: {
        [verifyResetMfa.pending]: (state, action) => {
            state.status = ReduxStatus.loading;
        },
        [verifyResetMfa.fulfilled]: (state, action) => {
            state.data = action.payload;
            state.status = ReduxStatus.loaded;
        },
        [verifyResetMfa.rejected]: (state, action) => {
            state.errorMessages = action.payload;
            state.status = ReduxStatus.loadFailed;
        }
    }
});

export default resetMfaSlice.reducer;
